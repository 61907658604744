@import './font-family';
@import './color';

*,
::before,
::after {
  font-family: $base-font-family;
  transition: color 0s ease, background-color 0.2s ease, border-color 0.2s ease,
    box-shadow 0.2s ease;
}

body {
  background-color: $header-color;

  &::after {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 45%;
    content: '';
    background-color: $footer-color;
  }
}
